@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

/* icon font */
/* @font-face {
  font-family: 'icomoon';
  src: url('../src/assets/font/icomoon.eot?q4ly9y');
  src: url('../src/assets/font/icomoon.eot?q4ly9y#iefix') format('embedded-opentype'),
    url('../src/assets/font/icomoon.ttf?q4ly9y') format('truetype'),
    url('../src/assets/font/icomoon.woff?q4ly9y') format('woff'),
    url('../src/assets/font/icomoon.svg?q4ly9y#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
} */

@font-face {
  font-family: 'aiyyna';
  src: url('../src/assets/font/aiyyna.eot?3xk7yj');
  src: url('../src/assets/font/aiyyna.eot?3xk7yj#iefix') format('embedded-opentype'),
    url('../src/assets/font/aiyyna.ttf?3xk7yj') format('truetype'),
    url('../src/assets/font/aiyyna.woff?3xk7yj') format('woff'),
    url('../src/assets/font/aiyyna.svg?3xk7yj#aiyyna') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

*,
::after,
::before {
  box-sizing: border-box;
}
:root {
  --primary-font-stack: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --primary-color: #2e69ce;
  --primary-text-color: #333333;
  --white-color: #ffffff;
  --gray-color: #868995;
  --light-gray: #bebec1;
  --green-color: #20c997;
  --dark-color: #333333;
  --primary-color-active: #2656a7;
  --primary-color-secondery-active: #cbcbcf;
  --regent-gray-color: #808a9d;
  --main-bg: #f9f9fa;
  --star-bg: #f8d648;
  --yellow-color: #ffc048;
  --deal-column-bg: #f9fafa;
  --danger-Base-bg: #ff322e;
  --danger-Base-active: #d5201c;
  --orange-color: #f08c00;
  --orange-lite-color: #ff6b6b;
  --medium-purple-color: #9775fa;
  --Platinum-color: #e4e6e8;
  --gainsboro-color: #dcdcdc;
  --spanish-gray: #999;
  --before-after-color: rgba(228, 230, 232, 0);
  --pagination-box-shadow: rgba(0, 0, 0, 0.05);
  --pagination-box-shadow-hover: rgba(0, 0, 0, 0.12);
  --maximum-yellow-red: #ffc168;
  --yellow-lite-color: rgba(242, 201, 76, 0.25);
  --independence-color: #4e5971;
  --mango-color: #fbbc04;
  --Cerulean-Crayola: #04a8d5;
  --yellow-green: #92cf48;
  --green-lite: #7eeb6e;
  --red-lite: #dc3732;
  --tomato: #ff6347;
  --Gunmetal: #2c333f;
  --sheen-green: #7ed321;
  --bright-navy-blue: #197dd2;
  --bright-navy-box: rgb(25 125 210 / 50%);
  --cultured-info-color: #f2f2f2;
  --gainsBoro-color: #dbdbdb;
  --roman-silver-color: #81878e;
  --lables-border-color: rgba(189, 189, 189, 0.6);
  --bright-navy-blue: #197dd2;
  --bright-navy-box: rgb(25 125 210 / 50%);
  --cultured-color: #f7f7f7;
  --gainsBoro-color: #dbdbdb;
  --roman-silver-color: #81878e;
  --lables-border-color: rgba(189, 189, 189, 0.6);
  --Gray-color: #bdbdbd;
  --Red-salsa-color: #eb5757;
  --bright-navy-blue: #197dd2;
  --bright-navy-box: rgb(25 125 210 / 50%);
  --gainsBoro-color: #dbdbdb;
  --roman-silver-color: #81878e;
  --lables-border-color: rgba(189, 189, 189, 0.6);
  --Gray-color: #bdbdbd;
  --Red-salsa-color: #eb5757;
  --disable-color: #b4b5ba;
  --error-bg-color: #feeeee;
  --Cultured-color:#EFEFEF;

  /* Border Color */
  --gray-border-color: #e4e4e5;
  --danger-Base-border: rgba(0, 0, 0, 0.2);
  --yellow-lite-border: #f2c94c;
  --alice-blue-border: #e3e8ee;

  /* Icon Color */
  --gray-icon-color: #7c7c7f;
  --icon-blue: #40c4ff;
  --gray-more-icon: #98989c;
  --sea-green-icon: #20c997;
  --mantis-icon: #6fbe6c;
  --callreject-icon: #ff322e;
  --check-icon: #748ffc;
  --message-icon: #ffd600;
  --callminus-icon: rgba(255, 255, 255, 0.7);
  --number-color: rgba(255, 255, 255, 0.6);
  --icon-green: #2dde98;
  --icon-united-nations-blue: #4f96f2;
  --icon-mango: #fbbb00;
  --icon-paradise-pink: #eb466e;
  --kelly-green: #3cbf27;
  --jonquil: #edc626;
  --icon-suggestion: #ed9190;

  /* Button Color */
  --gray-disable-color: #bebec1;

  /* nav color */
  --nav-dark-color: #202632;
  --nav-dark-color-hover: #2e3341;
  --nav-dark-color-focus: #494f5e;

  /*box-shadow*/
  --modal-box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);

  /*popup hover*/
  --popup-color-hover: #f2f2f2;

  --text-input-small: rgba(58, 62, 66, 0.15);
  --box-shadow: #f0f0f0;
  --box-shadow-secondary: rgba(37, 37, 38, 0.08);
  --modal-shadow: rgba(0, 0, 0, 0.15);
  --silver-sand: #c5c8cd;
  --menu-modal: rgba(0, 0, 0, 0.1);
  --inbox-hover-color: rgba(0, 0, 0, 0.08);
  --linear-gradient-white: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 1) 7%,
    #fff 30%,
    #fff 100%
  );
  --linear-gradient-dark: linear-gradient(180deg, #202632 60%, rgba(32, 38, 50, 0) 100%);
  --linear-gradient-dark-bottom: linear-gradient(360deg, #202632 87%, rgba(32, 38, 50, 0) 100%);
  --linear-gradient-delete: linear-gradient(270deg, #f9fafa -2.3%, #f9fafa 47%, rgba(249, 250, 250, 0) 100%);
  --linear-gradient-more: linear-gradient(270deg, #f9fafa -2.3%, #f9fafa 47%, rgba(249, 250, 250, 0) 100%);
  --linear-gradient-hover: linear-gradient(
    270deg,
    #f2f2f2 0%,
    rgba(242, 242, 242, 0.9) 47.92%,
    rgba(242, 242, 242, 0) 100%
  );
  --black-bg: rgba(0, 0, 0, 0.35);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--primary-font-stack);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  overflow: hidden;
  height: 100%;
}

.sidebarWrapper .arrow-navbar {
  padding: 2px 3px;
}
.sidebarWrapper .sidebarBlock .electron-app {
  margin: 0px 0 10px 0;
}
.sidebarWrapper .sidebarBlock .imgWrapper {
  padding: 0 0px 20px 0px;
}
.sidebarWrapper .arrow-navbar i {
  font-size: 16px;
}
.sidebarWrapper .resize-arrow-navbar {
  max-width: 50px;
  justify-content: center;
}
.sidebar + .resizeWrapper {
  margin-left: 48px;
}
.sidebar + .resizeWrapper .resize {
  left: 48px;
}
blockquote,
pre,
s {
  margin: 0;
  padding: 0;
}
.status-menu .MuiMenu-paper {
  margin-left: 55px;
}

.iconsvg {
  height: 20px;
}

.inDevelopment {
  display: none;
}

.ql-toolbar.ql-snow .ql-formats button svg {
  display: none;
}
.ql-picker-label svg {
  display: none;
}

.ql-editor.ql-blank::before {
  color: var(--disable-color);
}

.ql-expanded .ql-picker-label::after {
  font-family: 'aiyyna';
  content: '\e970';
  font-size: 20px;
  display: inline-block;
  position: absolute;
  top: 1px;
  right: 6px;
}
.ql-picker-label::after {
  font-family: 'aiyyna';
  content: '\e925';
  font-size: 20px;
  display: inline-block;
  position: absolute;
  top: 1px;
  right: 6px;
}
.iconsvg {
  height: 20px;
}
.ql-bold::after {
  font-family: 'aiyyna';
  content: '\e90d';
  font-size: 16px;
}
.ql-italic::after {
  font-family: 'aiyyna';
  content: '\e93d';
  font-size: 16px;
}
.ql-underline::after {
  font-family: 'aiyyna';
  content: '\e96f';
  font-size: 16px;
}
.ql-strike::after {
  font-family: 'aiyyna';
  content: '\e962';
  font-size: 16px;
}
.ql-blockquote::after {
  font-family: 'aiyyna';
  content: '\e955';
  font-size: 16px;
}
.ql-code-block::after {
  font-family: 'aiyyna';
  content: '\e918';
  font-size: 16px;
}
.ql-link::after {
  font-family: 'aiyyna';
  content: '\e942';
  font-size: 16px;
}
.ql-clean::after {
  font-family: 'aiyyna';
  content: '\e92c';
  font-size: 16px;
}
.ql-list[value*='ordered']::after {
  font-family: 'aiyyna';
  content: '\e94f';
  font-size: 16px;
}
.ql-list[value*='bullet']::after {
  font-family: 'aiyyna';
  content: '\e90f';
  font-size: 16px;
}
